import React, { useState, useEffect } from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import History from '../../components/aboutUs/History';
import Philosophy from '../../components/aboutUs/Philosophy';
import Team from '../../components/aboutUs/Team';
import Footer from "../../components/Footer";

const AboutUs = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

  return (
    <div>
      <div className={isMobile ? classes.aboutUsContainerMobile : classes.aboutUsContainer}>
        <History />
        <Philosophy />
        {/* <Team /> */}
      </div>
      <Footer />
    </div>
    
  );
};

export default AboutUs;
