import React from 'react';
import useStyles from '../../styles/styles';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import sponsorMap from '../../maps/sponsorMap';
import { Link } from 'react-router-dom';

const Sponsors = () => {
    const classes = useStyles();

    const sponsorsList = [
        { id: 1, name: 'Sunflower Energías', image: sponsorMap["sunflower"], url: 'https://www.sunflowerenergias.es/' },
        { id: 2, name: 'Talleres Family Car', image: sponsorMap["talleresfamilycar"], url: 'https://talleres-family-car.jimdosite.com/' },
    ];

    return (
        <section className={classes.about}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <AccountBalanceWalletIcon /> Patrocinadores
                </h2>
                <div className={classes.description}>
                    <p>Nuestro proyecto se ayuda gracias a nuestros patrocinadores.</p>
                </div>
                <div className={classes.sponsors}>
                    {sponsorsList.map((sponsor) => (
                        <a 
                            key={sponsor.id} 
                            className={classes.sponsorItem} 
                            href={sponsor.url} 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <img 
                                src={sponsor.image} 
                                alt={sponsor.name} 
                                className={classes.sponsorItemImage} 
                            />
                            <p className={classes.sponsorItemName}>{sponsor.name}</p>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Sponsors;