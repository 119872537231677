// sponsorMap.js
const getSponsorImageUrl = ( sponsorLogoImageName ) => {
  return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/sponsor/' + sponsorLogoImageName;
}

const sponsorMap = {
  barmadrid: getSponsorImageUrl('barmadrid.png'),
  sunflower: getSponsorImageUrl('sunflower.png'),
  talleresfamilycar: getSponsorImageUrl('talleresfamilycar.png'),
  default: getSponsorImageUrl('madflowers.png'),
};

export default sponsorMap;
